import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import ProtectedRoutes from "./ProtectedRoutes";
const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <ProtectedRoutes
        module="Login"
        path={`${match.url}hkadmin/dashboard`}
        component={asyncComponent(() => import("./NewDashboard"))}
      />
      <ProtectedRoutes
        module="Subscribe"
        path={`${match.url}hkadmin/subscriber`}
        component={asyncComponent(() => import("./Subscriber"))}
      />
      <ProtectedRoutes
        module="Contact"
        path={`${match.url}hkadmin/contact`}
        component={asyncComponent(() => import("./ContactUs"))}
      />
      <ProtectedRoutes
        module="Suggestion"
        path={`${match.url}hkadmin/suggestion`}
        component={asyncComponent(() => import("./ContactUs"))}
      />
      {/*<ProtectedRoutes path={`${match.url}hkadmin/profile`} component={asyncComponent(() => import('./MyAccount'))} />*/}

      <ProtectedRoutes
        module="Banner"
        path={`${match.url}hkadmin/banner`}
        exact
        component={asyncComponent(() => import("./Banner"))}
      />

      <ProtectedRoutes
        module="Banner"
        action="insert"
        path={`${match.url}hkadmin/banner/upsert/:id?`}
        component={asyncComponent(() => import("./Banner/BannerUpsertForm"))}
      />

      <ProtectedRoutes
        module="NonCert"
        path={`${match.url}hkadmin/noncert`}
        exact
        component={asyncComponent(() => import("./NonCert"))}
      />

      <ProtectedRoutes
        module="CertLayout"
        path={`${match.url}hkadmin/cert`}
        exact
        component={asyncComponent(() => import("./CertifiedDiamond"))}
      />

      <ProtectedRoutes 
        module="UploadStock"
        exact  
        path={`${match.url}hkadmin/Upload-Stock`}
        component={asyncComponent(() => import("./StockUpload"))}
      />

      <ProtectedRoutes
        module="NonCert"
        path={`${match.url}hkadmin/cert/upsert/:id?`}
        exact
        component={asyncComponent(() =>
          import("./CertifiedDiamond/CertifiedUpsert")
        )}
      />

      <ProtectedRoutes
        module="CertLayout"
        path={`${match.url}hkadmin/layout-master`}
        exact
        component={asyncComponent(() =>
          import("./CertifiedDiamond/LayoutMaster")
        )}
      />

      <ProtectedRoutes
        module="NonCert"
        path={`${match.url}hkadmin/noncertdetails/:id?`}
        exact
        component={asyncComponent(() => import("./NonCert/NonCertDetail"))}
      />

      <ProtectedRoutes
        module="NonCert"
        path={`${match.url}hkadmin/noncertdetails/upsert/:id?`}
        exact
        component={asyncComponent(() =>
          import("./NonCert/NonCertDiamondUpsert")
        )}
      />

      <ProtectedRoutes
        module="NonCert"
        action="insert"
        path={`${match.url}hkadmin/noncert/upsert/:id?`}
        exact
        component={asyncComponent(() => import("./NonCert/NonCertUpsert"))}
      />

      <ProtectedRoutes
        module="NonCert"
        action="insert"
        path={`${match.url}hkadmin/noncert/criteria/:id?`}
        exact
        component={asyncComponent(() =>
          import("./NonCert/NonCertCriteriaUpsert")
        )}
      />

      {/* <ProtectedRoutes module="NonCertDiamond"
        path={`${match.url}hkadmin/noncertdiamond`}
        exact
        component={asyncComponent(() => import("./NonCertDiamond"))}
      /> */}

      <ProtectedRoutes
        module="Location"
        path={`${match.url}hkadmin/location`}
        exact
        component={asyncComponent(() => import("./Location"))}
      />
      <ProtectedRoutes
        module="Location"
        action="insert"
        path={`${match.url}hkadmin/location/upsert/:id?`}
        component={asyncComponent(() => import("./Location/LocationUpsert"))}
      />
      <ProtectedRoutes
        module="Main Masters"
        path={`${match.url}hkadmin/master/main-master`}
        component={asyncComponent(() => import("./Master"))}
      />
      <ProtectedRoutes
        module="Sub Masters"
        action="insert"
        path={`${match.url}hkadmin/master/sub-master/:id?`}
        component={asyncComponent(() => import("./SubMaster"))}
      />
      <ProtectedRoutes
        module="Product Master"
        action="insert"
        path={`${match.url}hkadmin/product/:id?`}
        component={asyncComponent(() => import("./Product"))}
      />
      <ProtectedRoutes
        module="Account"
        path={`${match.url}hkadmin/account`}
        component={asyncComponent(() => import("./Account"))}
      />
      <ProtectedRoutes
        module="Account"
        path={`${match.url}hkadmin/account-details/:id?`}
        component={asyncComponent(() => import("./Account/AccountUpsert"))}
      />
      <ProtectedRoutes
        module="User"
        path={`${match.url}hkadmin/user`}
        component={asyncComponent(() => import("./User"))}
      />
      <ProtectedRoutes
        module="User"
        path={`${match.url}hkadmin/permission/:user?/:id?`}
        component={asyncComponent(() => import("./Permission"))}
      />
      <ProtectedRoutes
        module="User"
        path={`${match.url}hkadmin/rolemaster`}
        component={asyncComponent(() => import("./RoleManager"))}
      />

      {/* <ProtectedRoutes module="Event"
        path={`${match.url}hkadmin/event`}
        exact
        component={asyncComponent(() => import("./Event"))}
      />  */}
      {/* <ProtectedRoutes module="News"
        path={`${match.url}hkadmin/news`}
        exact
        component={asyncComponent(() => import("./Event"))}
      /> */}
      <ProtectedRoutes
        module="Show"
        path={`${match.url}hkadmin/show`}
        exact
        component={asyncComponent(() => import("./Event"))}
      />
      <ProtectedRoutes
        module="Popup"
        path={`${match.url}hkadmin/popup`}
        exact
        component={asyncComponent(() => import("./Event"))}
      />
      {/* <ProtectedRoutes module="Popup"
        path={`${match.url}hkadmin/popup-without-login`}
        exact
        component={asyncComponent(() => import("./Event"))}
      /> */}
      {/* <ProtectedRoutes module="Other"
        path={`${match.url}hkadmin/other`}
        exact
        component={asyncComponent(() => import("./Event"))}
      />
      <ProtectedRoutes module="CSR"
        path={`${match.url}hkadmin/csr`}
        exact
        component={asyncComponent(() => import("./Event"))}
      /> */}

      {/* <ProtectedRoutes module="Event" action="insert"
        path={`${match.url}hkadmin/event/upsert/:id?`}
        component={asyncComponent(() => import("./Event/EventUpsertForm"))}
      />
      <ProtectedRoutes module="CSR" action="insert"
        path={`${match.url}hkadmin/csr/upsert/:id?`}
        component={asyncComponent(() => import("./Event/EventUpsertForm"))}
      />
      <ProtectedRoutes module="News" action="insert"
        path={`${match.url}hkadmin/news/upsert/:id?`}
        exact
        component={asyncComponent(() => import("./Event/EventUpsertForm"))}
      /> */}
      <ProtectedRoutes
        module="Show"
        action="insert"
        path={`${match.url}hkadmin/show/upsert/:id?`}
        exact
        component={asyncComponent(() => import("./Event/EventUpsertForm"))}
      />
      {/* <ProtectedRoutes module="News" action="insert"
        path={`${match.url}hkadmin/news/upsert/:id?`}
        exact
        component={asyncComponent(() => import("./Event/EventUpsertForm"))}
      /> */}

      <ProtectedRoutes
        module="Popup"
        action="insert"
        path={`${match.url}hkadmin/popup/upsert/:id?`}
        component={asyncComponent(() => import("./Event/EventUpsertForm"))}
      />
      {/* <ProtectedRoutes module="Popup" action="insert"
        path={`${match.url}hkadmin/popup-without-login/upsert/:id?`}
        component={asyncComponent(() => import("./Event/EventUpsertForm"))}
      />

      <ProtectedRoutes module="Other" action="insert"
        path={`${match.url}hkadmin/other/upsert/:id?`}
        exact
        component={asyncComponent(() => import("./Event/EventUpsertForm"))}
      /> */}

      <ProtectedRoutes
        module="Size Masters"
        path={`${match.url}hkadmin/size-master`}
        component={asyncComponent(() => import("./SizeMaster"))}
      />
      <ProtectedRoutes
        module="Page Tracking"
        path={`${match.url}hkadmin/page-tracking`}
        component={asyncComponent(() => import("./PageTrack"))}
      />
      <ProtectedRoutes
        module="Guest"
        path={`${match.url}hkadmin/guest`}
        component={asyncComponent(() => import("./Guest"))}
      />
      <ProtectedRoutes
        module="Terms"
        path={`${match.url}hkadmin/terms`}
        exact
        component={asyncComponent(() => import("./Terms"))}
      />

      <ProtectedRoutes
        module="Country"
        path={`${match.url}hkadmin/country/:id?`}
        component={asyncComponent(() => import("./Country"))}
      />
      <ProtectedRoutes
        module="State"
        path={`${match.url}hkadmin/state/:id?`}
        component={asyncComponent(() => import("./State"))}
      />
      <ProtectedRoutes
        module="City"
        path={`${match.url}hkadmin/city/:id?/:countryId?`}
        component={asyncComponent(() => import("./City"))}
      />

      <Route
        module="Grid Column"
        path={`${match.url}hkadmin/gridcolumn`}
        component={asyncComponent(() => import("./GridColumn/index"))}
      />

      <ProtectedRoutes
        module="Stock Master"
        path={`${match.url}hkadmin/stock`}
        component={asyncComponent(() => import("./Diamond"))}
      />
      <ProtectedRoutes
        module="Cart"
        action="insert"
        path={`${match.url}hkadmin/cart/:id?`}
        component={asyncComponent(() => import("./Cart"))}
      />
      <ProtectedRoutes
        module="Watchlist"
        action="insert"
        path={`${match.url}hkadmin/watchlist/:id?`}
        component={asyncComponent(() => import("./Watchlist"))}
      />
      <ProtectedRoutes
        module="Reminder"
        action="insert"
        path={`${match.url}hkadmin/reminder/:id?`}
        component={asyncComponent(() => import("./Reminder"))}
      />
      <ProtectedRoutes
        module="Enquiry"
        action="insert"
        path={`${match.url}hkadmin/enquiry/:id?`}
        component={asyncComponent(() => import("./Enquiry"))}
      />
      <ProtectedRoutes
        module="Comment"
        action="insert"
        path={`${match.url}hkadmin/comment/:id?`}
        component={asyncComponent(() => import("./Comment"))}
      />

      <Route
        path={`${match.url}hkadmin/ex`}
        component={asyncComponent(() => import("./GridColumn/ex"))}
      />

      <ProtectedRoutes
        module="Parcel Master"
        path={`${match.url}hkadmin/parcel-master`}
        component={asyncComponent(() => import("./ParcelMaster"))}
      />

      <ProtectedRoutes
        module="Feedback"
        path={`${match.url}hkadmin/feedback`}
        component={asyncComponent(() => import("./Feedback"))}
      />
      <Route
        module="Dashboard Stone"
        path={`${match.url}hkadmin/featured-stone`}
        component={asyncComponent(() => import("./FeaturedStone"))}
      />

      <ProtectedRoutes
        module="Cabin"
        path={`${match.url}hkadmin/cabin`}
        component={asyncComponent(() => import("./Cabin"))}
      />
      <ProtectedRoutes
        module="Slot"
        path={`${match.url}hkadmin/slot`}
        component={asyncComponent(() => import("./Slot"))}
      />
      <ProtectedRoutes
        module="Schedule"
        path={`${match.url}hkadmin/schedule`}
        component={asyncComponent(() => import("./Schedule"))}
      />
      <ProtectedRoutes
        module="Cabin"
        path={`${match.url}hkadmin/appointment-list`}
        component={asyncComponent(() => import("./AppointmentList"))}
      />
      <Route
        path={`${match.url}hkadmin/setting`}
        component={asyncComponent(() => import("./Setting"))}
      />
      <ProtectedRoutes
        module="Notification Settings"
        path={`${match.url}hkadmin/notification-setting`}
        component={asyncComponent(() => import("./NotificationSetting"))}
      />
      <ProtectedRoutes
        module="Notification Settings"
        action="insert"
        path={`${match.url}hkadmin/notification/upsert/:id?`}
        component={asyncComponent(() =>
          import("./NotificationSetting/NotificationUpsert")
        )}
      />
      <Route
        path={`${match.url}hkadmin/settingtable`}
        component={asyncComponent(() => import("./Setting/settingTable"))}
      />
      <ProtectedRoutes
        module="Version"
        path={`${match.url}hkadmin/version-apk`}
        component={asyncComponent(() => import("./Version"))}
      />
      <ProtectedRoutes
        module="Login"
        path={`${match.url}hkadmin/report`}
        component={asyncComponent(() => import("./Dashboard"))}
      />
      <ProtectedRoutes
        module="Search Summary"
        path={`${match.url}hkadmin/login-summary`}
        component={asyncComponent(() => import("./LoginSummary"))}
      />
      <ProtectedRoutes
        module="Platform"
        path={`${match.url}hkadmin/platform`}
        component={asyncComponent(() => import("./Platform"))}
      />

      <ProtectedRoutes
        module="General Settings"
        path={`${match.url}hkadmin/general-setting`}
        component={asyncComponent(() => import("./GeneralSetting"))}
      />
      <ProtectedRoutes
        module="Transport"
        path={`${match.url}hkadmin/transport`}
        component={asyncComponent(() => import("./Transport"))}
      />
      <ProtectedRoutes
        module="Hospitality"
        path={`${match.url}hkadmin/hospitality`}
        component={asyncComponent(() => import("./Hospitality"))}
      />
      <ProtectedRoutes
        module="Career"
        path={`${match.url}hkadmin/career`}
        component={asyncComponent(() => import("./Career"))}
      />
      <ProtectedRoutes
        module="Search History"
        path={`${match.url}hkadmin/search-history`}
        component={asyncComponent(() => import("./SearchHistory"))}
      />

      <ProtectedRoutes module="Layout Search"
        path={`${match.url}hkadmin/layout-search`}
        component={asyncComponent(() => import("./LayoutSearch"))}
      />

      <ProtectedRoutes module="Search Summary"
        path={`${match.url}hkadmin/search-summary`}
        component={asyncComponent(() => import("./Summary"))}
      />

      <ProtectedRoutes module="Summary"
        path={`${match.url}hkadmin/summary`}
        component={asyncComponent(() => import("./Summary"))}
      />

      <ProtectedRoutes module="4 C"
        path={`${match.url}hkadmin/Four-C`}
        component={asyncComponent(() => import("./FourC"))}
      />
      {/* <ProtectedRoutes module="Best view diamond"
        path={`${match.url}hkadmin/best-view-diamond`}
        component={asyncComponent(() => import("./BestViewDaimond"))}
      /> */}
      <ProtectedRoutes
        module="Hit Counter"
        path={`${match.url}hkadmin/hit-counter`}
        component={asyncComponent(() => import("./HitCounter"))}
      />
      <ProtectedRoutes
        module="Sync Setting"
        path={`${match.url}hkadmin/sync-setting`}
        component={asyncComponent(() => import("./SyncSetting"))}
      />

      <ProtectedRoutes
        module="Parcel Order"
        path={`${match.url}hkadmin/parcel/order`}
        component={asyncComponent(() => import("./Parcel/Order"))}
      />
      <Route
        path={`${match.url}hkadmin/portfolio`}
        exact
        component={asyncComponent(() => import("./portfolio"))}
      />
      <ProtectedRoutes
        module="Diamond Journey"
        path={`${match.url}hkadmin/diamond-journey`}
        exact
        component={asyncComponent(() => import("./DiamondJourney"))}
      />
      {/* <Redirect from="*" to="/hkadmin/dashboard" /> */}
    </Switch>
  </div>
);

export default App;
