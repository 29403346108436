import * as _ from "lodash";
import moment from "moment";
import axios from "../util/Api";
import OpenNotification from "../constants/OpenNotifications";
import { BASE_SYNC_URL, USERS_TYPE, commonLogout } from "../constants/Common";
import { getItem } from "../util/storage";
const UtilService = {
  getPrimaryValue: (array, key) => {
    return array && array.length ? _.find(array, { isPrimary: true })[key] : "";
  },
  getDefaultsValue: (array, key) => {
    let c = _.find(array, { isPrimary: false });
    if (c) {
      return array && array.length
        ? _.find(array, { isPrimary: false })[key]
        : "";
    } else {
      return 0;
    }
  },
  checkDate: (start, end) => {
    var mStart = moment(start);
    var mEnd = moment(end);
    return mStart.isBefore(mEnd);
  },
  getPrimaryObj: array => {
    return array && array.length ? _.find(array, { isPrimary: true }) : "";
  },
  displayDate: date => {
    return date ? moment(date).format("MMM DD YYYY, hh:mm:ss a") : "-";
  },

  displyFromNow: date => {
    return date ? moment(date).fromNow() : "-";
  },
  getDays: date => {
    return date ? moment().diff(date, "days") + " days ago" : null;
  },
  getSyncValue() {
    let CurrentUserType = JSON.parse(getItem("user"));
    if (CurrentUserType.type === USERS_TYPE.SUPER_ADMIN) {
      return true;
    } else {
      let StatusData = JSON.parse(getItem("permission"));
      // console.log(StatusData, "PERmissiom");
      let SyncStatus = _.find(StatusData, function (o) {
        if (o.module === "sync") {
          return o;
        }
      });
      // console.log(SyncStatus, "SyncStatus");
      if (SyncStatus && SyncStatus.permissions.view) {
        //  localStorage.setItem('isSync',true)
        return true;
      } else {
        // localStorage.setItem('isSync',false)
        return false;
      }
    }
  },
  callApi(object, cb) {
    // const header_token = [
    //   {
    //     key: "Authorization",
    //     type: "text",
    //     value:
    //       "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVtYWlsIjoiIiwiaWQiOiI1Y2ZmNjllYjIwNzA5YzdlZTg5MGQyYWMifSwiaWF0IjoxNTYxMDAxMjYyLCJleHAiOjE1OTIxMDUyNjIsImF1ZCI6InNtYXJ0aHVtYW5vaWQuY29tIiwiaXNzIjoic21hcnRodW1hbm9pZC5jb20ifQ.sVckH-8BdBDPJ4_N9tacoKGfCHhITHLgZm1baF7_pTA"
    //   }
    // ];
    let token = JSON.parse(localStorage.getItem("token"));

    let headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token
      }
    };

    if (object.isSync === true) {
      headers.baseURL = BASE_SYNC_URL;
    }

    if (object.request && object.request.useCache) {
      object.request.useCache = true;
    }

    axios[object.method](object.url, object.request, headers)
      .then(({ data }) => {
        if (data.code === "E_UNAUTHORIZED") {
          commonLogout();
        } else {
          cb(null, data);
        }
      })
      .catch(error => {
        if (error.response && error.response.data) {
          if (error.response.data.code === "E_UNAUTHORIZED") {
            commonLogout();
          }
          OpenNotification({
            type: "error",
            title: error.response.data.message
          });
          cb(null, error.response.data);
        } else {
          OpenNotification({
            type: "error",
            title: "Something bad happen"
          });
          cb(error, {});
        }
      });
  }
};
export default UtilService;
//comment
