import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import _ from "lodash";
import CustomScrollbars from "../../util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "../../util/Auxiliary";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { MENU, USERS_TYPE } from "../../constants/Common";

import { ReactComponent as Dashboard } from "../../assets/svg/dashboard/dashboard.svg";
import { ReactComponent as Master } from "../../assets/svg/dashboard/master.svg";
import { ReactComponent as Account } from "../../assets/svg/dashboard/account.svg";
import { ReactComponent as User } from "../../assets/svg/dashboard/user.svg";
import { ReactComponent as Diamond } from "../../assets/svg/dashboard/diamond.svg";
import { ReactComponent as Stock } from "../../assets/svg/dashboard/stock.svg";
import { ReactComponent as Setting } from "../../assets/svg/dashboard/setting.svg";
import { ReactComponent as Enquiry } from "../../assets/svg/dashboard/enquiry.svg";
import { ReactComponent as Price } from "../../assets/svg/dashboard/price.svg";
import { ReactComponent as Feedback } from "../../assets/svg/dashboard/feedback.svg";
import { ReactComponent as Media } from "../../assets/svg/dashboard/media.svg";
import { ReactComponent as Cabin } from "../../assets/svg/dashboard/cabin.svg";
import { ReactComponent as Stocksummary } from "../../assets/svg/dashboard/stock-summary.svg";
import { ReactComponent as Appointment } from "../../assets/svg/dashboard/appointment.svg";
import { ReactComponent as ParcelMaster } from "../../assets/svg/dashboard/parcel-master.svg";
import { getItem } from "../../util/storage";
const SubMenu = Menu.SubMenu;

class SidebarContent extends Component {
  state = {
    collapsed: false
  };

  components = {
    Dashboard: Dashboard,
    Master: Master,
    Account: Account,
    User: User,
    Diamond: Diamond,
    Stock: Stock,
    Setting: Setting,
    Enquiry: Enquiry,
    Price: Price,
    Feedback: Feedback,
    Media: Media,
    Cabin: Cabin,
    Stocksummary: Stocksummary,
    Appointment: Appointment,
    ParcelMaster: ParcelMaster
  };

  returnSvg = svg => {
    const Tag = this.components[svg];
    return <Tag />;
  };

  getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { pathname, navStyle, auth } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    let loginUserType;

    if (auth && auth.authUser && auth.authUser.type) {
      loginUserType = auth.authUser.type;
    }
    let themeType = THEME_TYPE_LITE;
    let userPermissions = {};
    let PermissionData;
    if (loginUserType !== USERS_TYPE.SUPER_ADMIN) {
      PermissionData = getItem("permission")
        ? JSON.parse(getItem("permission"))
        : undefined;
    }

    userPermissions[loginUserType] = [];
    let MenuData = [];
    let is_allow_all_permission = false;
    if (!PermissionData || !PermissionData.length) {
      is_allow_all_permission = true;
      PermissionData = MENU;
    } else {
      _.each(PermissionData, function(s) {
        _.each(MENU, function(x) {
          if (
            !s.id &&
            s.module && x.name.toLowerCase() === s.module.toLowerCase()
          ) {
            s.id = x.id;
          }
          if (s.permissions && s.permissions.view) {
            if (x.children && x.children.length) {
              _.each(x.children, function(val) {
                if (
                  val &&
                  !s.id &&
                  val.name.toLowerCase() === s.module.toLowerCase()
                ) {
                  s.id = val.id;
                }
                // || (s.module && (val.name).toLowerCase() === (s.module).toLowerCase())
                if (s.id && val.id === s.id) {
                  PermissionData.push({
                    id: x.id,
                    module: x.name,
                    is_allow_all_parent: true
                  });
                }
              });
            }
          }
        });
      });
    }

    // PermissionData = _.uniqWith(PermissionData, _.isEqual);
    // console.log(PermissionData, 'PermissionData');
    _.each(PermissionData, function(s) {
      if (
        (s.permissions && s.permissions.view) ||
        is_allow_all_permission ||
        s.is_allow_all_parent
      ) {
        MenuData.push({ name: s.module, id: s.id });
        userPermissions[loginUserType].push(s.id);
        if (is_allow_all_permission) {
          if (s.children && s.children.length) {
            _.each(s.children, function(child) {
              MenuData.push({ name: child.module, id: child.id });
              userPermissions[loginUserType].push(child.id);
            });
          }
        }
      }
    });

    userPermissions[loginUserType] = _.uniqWith(
      userPermissions[loginUserType],
      _.isEqual
    );

    return (
      <Auxiliary className="hkSidebarMenu">
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              mode="inline"
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              // inlineCollapsed={true}
            >
              {MENU.map(menu => {
                return menu.children && menu.children.length
                  ? userPermissions[loginUserType].indexOf(menu.id) > -1 && (
                      <SubMenu
                        className={this.getNavStyleSubMenuClass(navStyle)}
                        key={menu.name}
                        title={
                          <span className="sub-menu-dropdown">
                            {menu.svg ? this.returnSvg(menu.svg) : ""}
                            <span className="vertical-menu">{menu.name}</span>
                          </span>
                        }
                      >
                        {menu.children.map(m => {
                          return (
                            userPermissions[loginUserType].indexOf(m.id) >
                              -1 && (
                              <Menu.Item
                                key={m.path && m.path.replace("/", "")}
                              >
                                <Link to={m.path}>{m.name}</Link>
                              </Menu.Item>
                            )
                          );
                        })}
                      </SubMenu>
                    )
                  : userPermissions[loginUserType].indexOf(menu.id) > -1 && (
                      <Menu.Item key={menu.path.replace("/", "")}>
                        <Link to={menu.path}>
                          {menu.svg ? this.returnSvg(menu.svg) : ""}
                          <span className="vertical-menu">{menu.name}</span>
                        </Link>
                      </Menu.Item>
                    );
              })}
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ auth, settings }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  return { navStyle, themeType, locale, pathname, auth };
};
export default connect(mapStateToProps)(SidebarContent);
