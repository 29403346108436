import _ from "lodash";
export const MENU = [
  {
    path: "/hkadmin/dashboard",
    componentName: "Dashboard",
    id: 178,
    name: "Dashboard",
    svg: "Dashboard",
    icon: "dashboard"
  },

  {
    name: "Masters",
    svg: "Master",
    icon: "widgets",
    id: 61,
    children: [
      {
        path: "/hkadmin/master/main-master",
        componentName: "Master",
        id: 71,
        svg: "Master",
        name: "Main Masters",
        icon: "widgets"
      },
      {
        path: "/hkadmin/master/sub-master",
        componentName: "SubMaster",
        id: 72,
        name: "Sub Masters",
        icon: "widgets"
      },
      {
        path: "/hkadmin/size-master",
        componentName: "Sizemaster",
        id: 73,
        name: "Size Masters",
        icon: "widgets"
      },

      {
        path: "/hkadmin/terms",
        componentName: "terms",
        id: 75,
        name: "Terms",
        icon: "widgets"
      },
      {
        path: "/hkadmin/product",
        componentName: "Product",
        id: 131,
        name: "Product Master",
        icon: "widgets"
      },
      {
        path: "/hkadmin/stock",
        componentName: "Diamond",
        id: 91,
        name: "Stock Master",
        // svg: "Stock",
        icon: "widgets"
      },
      {
        path: "/hkadmin/country",
        componentName: "Country",
        id: 76,
        name: "Country",
        icon: "widgets"
      },
      {
        path: "/hkadmin/state",
        componentName: "State",
        id: 78,
        name: "State",
        icon: "widgets"
      },
      {
        path: "/hkadmin/city",
        componentName: "City",
        id: 77,
        name: "City",
        icon: "widgets"
      },

      {
        path: "/hkadmin/notification-setting",
        componentName: "NotificationSetting",
        id: 123,
        name: "Notification Master",
        icon: "widgets"
      },
      {
        path: "/hkadmin/parcel-master",
        componentName: "ParcelMaster",
        id: 121,
        name: "Parcel Master",
        // svg: "ParcelMaster",
        icon: "widgets"
      },
      {
        path: "/hkadmin/featured-stone",
        componentName: "FeaturedStone",
        id: 104,
        // svg: "Diamond",
        name: "Dashboard Stone",
        icon: "widgets"
      },
      {
        path: "/hkadmin/noncert",
        componentName: "NonCert",
        id: 182,
        name: "NonCert",
        icon: "widgets"
      },
      {
        path: "/hkadmin/layout-master",
        componentName: "Layout Masters",
        id: 181,
        name: "Layout Masters",
        icon: "widgets"
      },
      {
        path: "/hkadmin/cert",
        componentName: "CertLayout",
        id: 183,
        name: "CertLayout",
        icon: "widgets"
      },
      {
        path: "/hkadmin/Upload-Stock",
        componentName: "UploadStock",
        id: 180,
        name: "UploadStock",
        icon: "widgets"
      },
    ]
  },
  {
    name: "Customer",
    svg: "Account",
    icon: "widgets",
    id: 171,
    children: [
      {
        path: "/hkadmin/account",
        componentName: "Account",
        // svg: "Account",
        id: 101,
        name: "Company",
        icon: "widgets"
      },
      {
        path: "/hkadmin/user",
        componentName: "User",
        // svg: "User",
        id: 113,
        name: "User",
        icon: "widgets"
      },
      {
        path: "/hkadmin/page-tracking",
        componentName: "PageTracking",
        id: 125,
        name: "Page Tracking",
        icon: "widgets"
      },
      {
        path: "/hkadmin/guest",
        componentName: "Guest",
        id: 165,
        name: "Guest",
        icon: "widgets"
      }
    ]
  },

  {
    name: "Report",
    svg: "Master",
    icon: "widgets",
    // svg: "Dashboard",
    id: 143,
    children: [
      {
        path: "/hkadmin/report",
        componentName: "Dashboard",
        id: 60,
        name: "Login"
      },
      {
        path: "/hkadmin/login-summary",
        componentName: "LoginSummary",
        id: 184,
        name: "Login Summary"
      },
      {
        path: "/hkadmin/search-history",
        componentName: "SearchHistory",
        id: 162,
        name: "Search History"
      },
      {
        path: "/hkadmin/layout-search",
        componentName: "LayoutSearch",
        id: 160,
        name: "Layout Search"
      },
      {
        path: "/hkadmin/search-summary",
        componentName: "SearchSummary",
        id: 161,
        name: "Search Summary"
      },
      {
        path: "/hkadmin/four-c",
        componentName: "FourC",
        id: 179,
        name: "4 C"
      },
      // {
      //   path: "/hkadmin/best-view-diamond",
      //   componentName: "BestViewDiamond",
      //   id: 179,
      //   name: "Best view diamond"
      // },
      {
        path: "/hkadmin/hit-counter",
        componentName: "HitCounter",
        id: 163,
        name: "Hit Counter"
      }
    ]
  },

  {
    name: "Marketing",
    icon: "widgets",
    svg: "Media",
    id: 109,
    children: [
      {
        path: "/hkadmin/banner",
        componentName: "Banner",
        id: 103,
        name: "Banner",
        icon: "widgets"
      },
      // {
      //   path: "/hkadmin/event",
      //   componentName: "Event",
      //   id: 102,
      //   name: "Event",
      //   icon: "widgets"
      // },
      // {
      //   path: "/hkadmin/news",
      //   componentName: "News",
      //   id: 110,
      //   name: "News",
      //   icon: "widgets"
      // },
      {
        path: "/hkadmin/show",
        componentName: "Show",
        id: 112,
        name: "Show",
        icon: "widgets"
      },
      // {
      //   path: "/hkadmin/csr",
      //   componentName: "CSR",
      //   id: 126,
      //   name: "CSR",
      //   icon: "widgets"
      // },
      {
        path: "/hkadmin/popup",
        componentName: "Popup",
        id: 174,
        name: "Popup",
        icon: "widgets"
      }
      // {
      //   path: "/hkadmin/popup-without-login",
      //   componentName: "PopupWithoutLogin",
      //   id: 175,
      //   name: "Popup Without Login",
      //   icon: "widgets"
      // },
      // {
      //   path: "/hkadmin/other",
      //   componentName: "Other",
      //   id: 111,
      //   name: "Other",
      //   icon: "widgets"
      // }
    ]
  },
  // {
  //   name: "NonCertLayout",
  //   icon: "widgets",
  //   svg: "Media",
  //   id: 180,
  //   children: [
  //     {
  //       path: "/hkadmin/noncert",
  //       componentName: "NonCert",
  //       id: 181,
  //       name: "NonCert",
  //       icon: "widgets"
  //     }
  //     // {
  //     //   path: "/hkadmin/noncertdiamond",
  //     //   componentName: "NonCertDiamond",
  //     //   id: 182,
  //     //   name: "NonCertDiamond",
  //     //   icon: "widgets"
  //     // },
  //   ]
  // },
  {
    name: "Appointment",
    icon: "widgets",
    svg: "Appointment",
    id: 119,
    children: [
      {
        path: "/hkadmin/cabin",
        componentName: "Cabin",
        id: 120,
        // svg: "Cabin",
        name: "Cabin",
        icon: "widgets"
      },
      {
        path: "/hkadmin/slot",
        componentName: "Slot",
        id: 166,
        name: "Slot",
        icon: "widgets"
      },
      {
        path: "/hkadmin/schedule",
        componentName: "Schedule",
        id: 118,
        name: "Schedule",
        icon: "widgets"
      },
      {
        path: "/hkadmin/location",
        componentName: "Location",
        id: 164,
        name: "Location",
        icon: "widgets"
      },
      {
        path: "/hkadmin/appointment-list",
        componentName: "AppointmentList",
        id: 119,
        name: "Appointment List",
        icon: "widgets"
      }
    ]
  },

  {
    name: "Stock Summary",
    icon: "widgets",
    svg: "Stocksummary",
    id: 169,
    children: [
      {
        path: "/hkadmin/cart",
        componentName: "Cart",
        id: 105,
        name: "Cart",
        icon: "widgets"
      },
      {
        path: "/hkadmin/watchlist",
        componentName: "Watchlist",
        id: 106,
        name: "Watchlist",
        icon: "widgets"
      },
      {
        path: "/hkadmin/reminder",
        componentName: "Reminder",
        id: 107,
        name: "Reminder",
        icon: "widgets"
      },
      {
        path: "/hkadmin/enquiry",
        componentName: "Enquiry",
        id: 108,
        name: "Enquiry",
        icon: "widgets"
      },
      {
        path: "/hkadmin/comment",
        componentName: "Comment",
        id: 173,
        name: "Comment",
        icon: "widgets"
      },
      {
        path: "/hkadmin/diamond-journey",
        componentName: "DiamondJourney",
        id: 177,
        name: "Diamond Journey",
        icon: "widgets"
      }
    ]
  },
  // {
  //   name: 'Diamonds',
  //   icon: 'widgets',
  //   svg:'Diamond',
  //   id: 62,
  //   children: [
  //     {
  //       path: '/hkadmin/setup/quick-search',
  //       componentName: 'Printer',
  //       id: 80,
  //       name: 'Quick Search',
  //       icon: 'widgets'
  //     },
  //     {
  //       path: '/hkadmin/setup/search',
  //       componentName: 'Pen',
  //       id: 80,
  //       name: 'Search',
  //       icon: 'widgets'
  //     },
  //     {
  //       path: '/hkadmin/setup/demand',
  //       componentName: 'Device',
  //       id: 81,
  //       name: 'Demand',
  //       icon: 'widgets'
  //     },
  //     {
  //       path: '/hkadmin/setup/latest-deal-offer',
  //       componentName: 'version',
  //       id: 82,
  //       name: 'Latest Deal(Offer)',
  //       icon: 'widgets'
  //     },
  //     {
  //       path: '/hkadmin/latest-hold',
  //       componentName: 'AssignDoctor',
  //       id: 83,
  //       name: 'Latest hold'
  //     },
  //     {
  //       path: '/hkadmin/setup/latest-consignment',
  //       componentName: 'Printer',
  //       id: 84,
  //       name: 'Latest consignment',
  //       icon: 'widgets'
  //     },
  //     {
  //       path: '/hkadmin/setup/latest-purchase',
  //       componentName: 'Pen',
  //       id: 85,
  //       name: 'Latest Purchase',
  //       icon: 'widgets'
  //     },

  //     {
  //       path: '/hkadmin/appointment',
  //       componentName: 'AssignDoctor',
  //       id: 88,
  //       name: 'Appointment'
  //     },
  //     {
  //       path: '/hkadmin/setup/deal-offer-history',
  //       componentName: 'version',
  //       id: 89,
  //       name: 'Deal(Offer)history',
  //       icon: 'widgets'
  //     },
  //     {
  //       path: '/hkadmin/purchase-history',
  //       componentName: 'AssignDoctor',
  //       id: 90,
  //       name: 'Purchase history'
  //     }
  //   ]
  // },
  {
    name: "Forum",
    id: 64,
    svg: "Enquiry",
    icon: "widgets",
    children: [
      {
        path: "/hkadmin/contact",
        componentName: "ContactUs",
        id: 95,
        name: "Contact",
        icon: "widgets"
      },
      {
        path: "/hkadmin/suggestion",
        componentName: "ContactUs",
        id: 172,
        name: "Suggestion",
        icon: "widgets"
      },
      {
        path: "/hkadmin/subscriber",
        componentName: "Subscriber",
        id: 96,
        name: "Subscribe",
        icon: "widgets"
      },
      {
        path: "/hkadmin/career",
        componentName: "Career",
        id: 97,
        name: "Career",
        icon: "widgets"
      },
      {
        path: "/hkadmin/feedback",
        componentName: "Feedback",
        id: 170,
        // svg: "Feedback",
        name: "Feedback",
        icon: "widgets"
      }
    ]
  },

  {
    name: "Setting",
    id: 63,
    svg: "Setting",
    icon: "widgets",
    children: [
      {
        path: "/hkadmin/setting",
        componentName: "Setting",
        id: 122,
        name: "Notification Settings",
        icon: "widgets"
      },
      {
        path: "/hkadmin/general-setting",
        componentName: "GeneralSetting",
        id: 124,
        name: "General Settings",
        icon: "widgets"
      },

      {
        path: "/hkadmin/sync-setting",
        componentName: "SyncSetting",
        id: 145,
        name: "Sync Setting",
        icon: "widgets"
      },
      {
        path: "/hkadmin/gridcolumn",
        componentName: "GridColumn",
        id: 94,
        name: "Grid Column",
        icon: "widgets"
      },

      {
        path: "/hkadmin/version-apk",
        componentName: "Version",
        id: 176,
        name: "Version",
        // svg: "ParcelMaster",
        icon: "widgets"
      }
    ]
  },

  {
    name: "Converge Service",
    id: 127,
    svg: "Setting",
    icon: "widgets",
    children: [
      {
        path: "/hkadmin/hospitality",
        componentName: "Hospitality",
        id: 128,
        name: "Hospitality",
        icon: "widgets"
      },

      {
        path: "/hkadmin/transport",
        componentName: "Transport",
        id: 129,
        name: "Transport",
        icon: "widgets"
      }
    ]
  },
  {
    name: "Parcel",
    icon: "widgets",
    svg: "Stocksummary",
    id: 130,
    children: [
      {
        path: "/hkadmin/parcel/order",
        componentName: "ParcelOrder",
        id: 167,
        name: "Parcel Order",
        icon: "widgets"
      }
    ]
  }

  // {
  //   name: 'Price',
  //   id: 65,
  //   svg:'Price',
  //   icon: 'widgets',
  //   children: [
  //     {
  //       path: '/hkadmin/discount-price',
  //       componentName: 'SamplePage',
  //       id: 97,
  //       name: 'Discount Price',
  //       icon: 'widgets'
  //     },
  //     {
  //       path: '/hkadmin/rap-price',
  //       componentName: 'PrintedOrder',
  //       id: 98,
  //       name: 'Rap Price',
  //       icon: 'widgets'
  //     }
  //   ]
  // },
];
let Data = [];
let masterChild = [];
_.each(MENU, function (s) {
  if (s.children) {
    _.each(s.children, function (x) {
      Data.push({
        module: x.name,
        title: _.capitalize(x.name),
        id: x.id,
        permissions: {
          insert: false,
          update: false,
          delete: false,
          view: false
        }
      });
      masterChild.push({ name: x.name, id: x.id, parentid: s.id });
    });
  } else {
    Data.push({
      module: s.name,
      title: _.capitalize(s.name),
      id: s.id,
      permissions: { insert: false, update: false, delete: false, view: false }
    });
  }
});

Data.push(
  {
    module: "sync",
    title: "Sync Function",
    id: 201,
    permissions: { insert: false, update: false, delete: false, view: false }
  },
  {
    module: "UserActive",
    title: "User Active",
    id: 202,
    permissions: { insert: false, update: false, delete: false, view: false }
  },
  {
    module: "UserResetpass",
    title: "User ResetPassword",
    id: 203,
    permissions: { insert: false, update: false, delete: false, view: false }
  },
  {
    module: "UserPagePermission",
    title: "User Page PermissionIcon",
    id: 204,
    permissions: { insert: false, update: false, delete: false, view: false }
  }
);

export const SUPERADMINPERMISSION = Data;
export const MASTERCHILD = {
  child: masterChild
};

export const CALENDER_FIELD = [
  "SHOW",
  "INFO",
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER"
];

export const USER_TYPE = {
  ADMIN: {
    SUPER: 1,
    GENERAL: 11
  },
  PHARMACY: {
    ADMIN: 2,
    PHARMACIST: 21,
    LICENSED_TECHNICIAN: 22,
    INTERN: 23,
    SHIPPER: 24,
    RECEIVER: 25
  },
  ORGANIZATION: {
    ADMIN: 3
  },
  HOME: {
    ADMIN: 4,
    HOME_AREA: {
      ADMIN: 5,
      DOCTOR: 51,
      NURSE: 52,
      STAFF: 53,
      PATIENT: 54
    }
  }
};

export const FILE_TYPES = {
  pdf: ["application/pdf"],
  image: ["image/jpeg", "image/png", "image/jpg", "image/gif"],
  json: ["application/json"],
  video: ["video/mp4", "video/gif", "video/mpeg"]
};

export const VIDEO_FILE_TYPES = {
  pdf: ["application/pdf"],
  image: ["image/jpeg", "image/png", "image/jpg", "image/gif"],
  json: ["application/json"]
};

export const VERSION_PLATFORM = {
  ANDROID: 1,
  IPHONE: 2
};

export const EVENT = {
  TYPE: {
    EVENT: "1",
    NEWS: "2",
    SHOW: "3",
    OTHER: "4",
    CSR: "5",
    POPUP: "6",
    POPUP_WITHOUT_LOGIN: "7"
    // BIRTHDAY: "11"
  }
};

export const FEATURE_STONE = {
  STONE_OF_DAY: "stone_of_day",
  BEST_OF_HK: "best",
  EXCLUSIVE_STONE: "exclusive",
  PAIR: "pair",
  FEATURE_STONE: "stone"
};
export const USERS_TYPE = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  CUSTOMER: 3,
  GUEST: 4,
  PERMANENT_GUEST: 5,
  EMPLOYEE: 6
};
export const Employe_Type = {
  commonPER: 2,
  AdminPER: 1
};

export const PERMISSION_TYPE = {
  user: "user",
  role: "role"
};

export const PARCEL_PRICE_OPERATION_TYPE = {
  SAVE_AS_NEW: "1",
  UPDATE: "2"
};

export const commonLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("permission");

  const tokens = JSON.parse(localStorage.getItem("token"));
  if (!tokens) {
    window.location.href = window.location.origin + "/hkadmin/signin";
  }
};

export const PARCEL_PRICE_SHAPE = {
  ROUND: "Round",
  PRINCESS: "Princess",
  PEAR: "Pear",
  OVAL: "Oval",
  MARQUISE: "Marquise",
  EMERALD: "Emerald"
};

export const PARCEL_PRICE_SHAPE_SALES_PERSON = {
  ALL: "All",
  ROUND: "Round",
  PRINCESS: "Princess",
  PEAR: "Pear",
  OVAL: "Oval",
  MARQUISE: "Marquise",
  FANCY: "Fancy"
};

export const NEWS = {
  DISPLAYON: {
    WEB: "1",
    APP: "2"
  },
  TYPE: {
    NOTIFICATION: "1",
    "HELLO BAR": "2",
    "TRADE SHOW": "3",
    OTHER: "4"
  }
};

export const BANNER_TYPES = {
  LOGIN_PAGE: 1,
  REGISTER_PAGE: 2,
  FORGOT_PASSWORD_PAGE: 3,
  GUEST_LOGIN: 4,
  CALENDAR: 5,
  DASHBOARD: 6,
  HOME_PAGE_ACTIVITY: 7,
  HOME_PAGE_PHILOSOPHY: 8,
  DIAMOND_SEARCH_PAGE: 9,
  MATCH_PAIR: 10,
  WHATSAPP_BANNER: 11
};

export const PAGE_TRACKING_DEVICE = {
  WEB: 1,
  ANDROID: 2,
  IOS: 3
};

export const PARCEL_ORDER_STATUS = {
  PENDING: 1,
  APPROVE: 2,
  CANCEL: 3
};

export const PAGE_TRACKING_ACTION = {
  OPEN: "open",
  CLOSE: "close",
  COMPLETE: "complete",
  RESET: "reset",
  SELECT: "select",
  CUSTOM: "custom",
  FANCY: "fancy",
  WHITE: "white",
  LIST: "list",
  CLICK: "click",
  PRINT: "print",
  EXCEL: "excel",
  GRID: "grid",
  VIDEO: "video",
  PICTURE: "picture",
  CERTIFICATE: "certificate",
  CHANGE: "change",
  DOWNLOAD: "download",
  FOCUS: "focus",
  SEARCH: "search"
};
export const PAGE_TRACKING_SECTION = {
  ADVANCE_SEARCH: "AdvanceSearch",
  ADD_DEMAND: "AddDemand",
  SAVE_SEARCH: "SavedSearch",
  UPLOAD_EXCEL: "UploadExcel",
  CARAT_SIZE: "CaratSize",
  COLOR: "Color",
  DIAMONDS: "Diamonds",
  MODIFY: "Modify",
  STICKY_FILTER: "StickyFilter",
  PLACE_ORDER: "PlaceOrder",
  SHIPMENT: "Shipment",
  ENQUIRY: "Enquiry",
  UPDATE: "Update",
  EXCEL: "excel",
  EXPORT: "Export",
  EXPORT_SEND_EMAIL: "Export-Send_Email",
  ADD_TO_CART: "AddToCart",
  ADD_TO_WATCHLIST: "AddToWatchList",
  COMMENT: "Comment",
  REMINDER: "Reminder",
  VIEW: "View",
  FILTER: "Filter",
  SETTING: "Setting",
  TABLE_ADD_TO_CART: "Table-AddToCart",
  TABLE_ADD_TO_WATCHLIST: "Table-AddToWatchList",
  TABLE_DELETE: "Table-Delete",
  TABLE_COMMENT: "Table-Comment",
  TABLE_REMINDER: "Table-Reminder",
  TABLE_DETAILS: "Table-Details",
  EDIT: "Edit",
  DELETE: "Delete",
  SEARCH: "Search",
  CHANGE_PASSWORD: "ChangePassword",
  INVENTORY: "Inventory",
  MY_CART: "MyCart",
  CALCULATOR: "Calculator",
  TRACK_SHIPMENT: "TrackShipment",
  LOCALE: "Locale"
};
export const PAGE_TRACKING_PAGE = {
  DIAMOND_SEARCH: "DiamondSearch",
  FANCY_SEARCH: "FancySearch",
  MY_DEMAND: "MyDemand",
  MY_SAVE_SEARCH: "MySavedSearch",
  SEARCH_RESULT: "SearchResult",
  BEST_OF_HK: "BestOfHK",
  UPCOMING_DIAMOND: "UpcomingDiamond",
  I_AM_LUCKY: "I'mLucy",
  NEW_DIAMOND: "NewDiamond",
  CART: "MyCart",
  WATCHLIST: "MyWatchlist",
  REMINDER: "MyReminder",
  COMMENT: "MyComment",
  ENQUIRY: "MyEnquiry",
  RECOMMENDED: "Recommended",
  ORDER: "MyOrder",
  ACCOUNT: "MyAccount",
  HEADER: "Header",
  ABOUTUS: "AboutUs",
  ADDRESS: "Address",
  APPOINTMENT: "Appointment",
  CHANGE_PASSWORD: "ChangePassword",
  COMPARE: "Compare",
  CONTACT: "Contact",
  DIAMOND_DETAILS: "DiamondDetail",
  DRAWER: "Drawer",
  EXCLUSIVE_DIAMOND: "ExclusiveDiamond",
  FANCY: "Fancy",
  HOME: "Home",
  MATCH_PAIRS: "MatchPairs",
  MY_PURCHASE: "MyPurchase",
  NEW_GOODS: "NewGoods",
  NEWS: "News",
  NOTIFICATION: "Notification",
  OFFLINE_SEARCH_HISTORY: "OfflineSearchHistory",
  OFFLINE_TRACK: "OfflineTrack",
  PARCEL: "Parcel",
  PICKUP: "Pickup",
  PICKUP_REQUEST: "PickupRequest",
  PRICE_CALCULATOR: "PriceCalculator",
  PRIVACY_POLICY: "PrivacyPolicy",
  PROFILE: "Profile",
  PURCHASE: "Purchase",
  QRCODE: "QRCode",
  QUICKSEARCH: "QuickSearch",
  RATE_US: "RateUs",
  SAVE_SEARCH: "SaveSearch",
  SHOW_STONES: "ShowStones",
  SIDE_MENU: "SideMenu",
  TERMS_CONDITION: "Terms&Condition",
  TRANSPORT: "TransPort",
  VIRTUAL_TOUR: "VertualTour",
  VOICE_SEARCH: "VoiceSearch",
  DEMAND: "demand",
  LOGIN: "login",
  OFFER: "offer",
  OFFLINE_DOWNLOAD: "offlinedownload",
  PAIR_SETTING: "pair setting",
  SCREENSHOT: "screenShot",
  SHIPMENT: "shipment",
  STONE_OF_THE_DAY: "stoneoftheday",
  SUGESSIONS: "sugessions",
  TRACK: "track",
  USER: "user"
};

export const BANNER_TYPES_IMAGE = {
  Image: 1,
  Text: 2
};

export const TRACK_TYPES = {
  Cart: 1,
  Watchlist: 2,
  Enquiry: 6,
  Reminder: 5
};

export const TRANSPORT_STATUS = {
  Pending: "1",
  Done: "2",
  Cancel: "3"
};
export const HOSPITALITY_STATUS = {
  Pending: "0",
  approved: "1",
  Cancel: "3"
};

export const LOGIN_PLATFORM = {
  Android: "2",
  IOS: "3",
  Web: "1",
  ipad: "6"
};

export const SETTING_TYPE = {
  OTHER_SETTING: 1,
  NOTIFICATION_SETTING: 2,
  ORDER_SETTINGS: 3,
  OFFLINE_SETTINGS: 4,
  EMAIL_RECEIVER_SETTINGS: 5,
  SALES_PERSON_SETTINGS: 7,
  SALES_AMOUNT_MONTH: 11,
  ORDER_DISCOUNT: 12,
  MOBILE_ORDER_DISCOUNT: 14,
  MONGODB_ORDER: 101
};

export const PERMISSION = [
  {
    module: "dashboard",
    title: "Dashboard",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "searchDiamond",
    title: "Search Diamond",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },

  {
    module: "quickSearch",
    title: "Quick Search",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "fancySearch",
    title: "Fancy Search",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "mySavedSearch",
    title: "My Saved Search",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "myDemand",
    title: "My Demand",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "searchResult",
    title: "Search Result",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "compare",
    title: "Compare",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "showSelected",
    title: "Show Selected",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "matchPair",
    title: "Match Pair",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "iAmLucky",
    title: "I Am Lucky",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "parcelList",
    title: "Parcel List",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "newGoods",
    title: "New Goods",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "bestOfHK",
    title: "Best Of HK",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },

  {
    module: "upcomingDiamonds",
    title: "Upcoming Diamonds",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },

  {
    module: "cart",
    title: "Cart",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "reminder",
    title: "Reminder",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "watchlist",
    title: "Watchlist",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "comment",
    title: "Comment",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "enquiry",
    title: "Enquiry",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "order",
    title: "Order",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "shipment",
    title: "Shipment",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "transport",
    title: "Transport",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "purchase",
    title: "Purchase",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "hospitality",
    title: "Hospitality",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "offlineStock",
    title: "Offline Stock",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "pricecalculator",
    title: "Price Calculator",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "stoneoftheday",
    title: "Stone Of The Day",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "appointment",
    title: "Appointment",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "diamondHistory",
    title: "Diamond History",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "inventory",
    title: "Inventory",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "pricing",
    title: "Pricing",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "RoughImage",
    title: "Rough Image",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  },
  {
    module: "XRayZip",
    title: "X Ray Zip ",
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false
    }
  }
];
export const SETTING_PAGES = [
  {
    action: "cart",
    label: "Cart"
  },
  {
    action: "watchlist",
    label: "Watchlist"
  },
  {
    action: "comment",
    label: "My Comment"
  },
  {
    action: "enquiry",
    label: "My Enquiry"
  },
  {
    action: "demand",
    label: "My Demand"
  },
  {
    action: "demand-expiry",
    label: "Demand Expiry"
  },
  {
    action: "reminder",
    label: "Reminder"
  },
  {
    action: "compare",
    label: "Compare"
  },
  {
    action: "mySavedSearch",
    label: "my Saved Search"
  },
  {
    action: "diamondSearch",
    label: "Diamond Search"
  },
  {
    action: "searchResult",
    label: "Search Result"
  },
  {
    action: "showSelected",
    label: "Show Selected"
  },
  {
    action: "newGoods",
    label: "New Goods"
  },
  {
    action: "upcommingDiamond",
    label: "Upcomming Diamond"
  },
  {
    action: "searchReminder",
    label: "Search Reminder"
  },
  {
    action: "exclusive",
    label: "Exclusive Stone"
  },
  {
    action: "feature-pair",
    label: "Feature Pair"
  },
  {
    action: "feature-stone",
    label: "Feature Stone"
  },
  {
    action: "stone-of-day",
    label: "Stone Of Day"
  },
  {
    action: "best",
    label: "Best"
  },
  {
    action: "other",
    label: "Other"
  },
  {
    action: "offer",
    label: "Offer"
  },
  {
    action: "order-place",
    label: "Order Place"
  },
  {
    action: "Appointment",
    label: "Appointment"
  },
  {
    action: "shipment",
    label: "Shipment"
  }
  // ,{
  //   action:"order-reject",
  //   label:"Order Reject"
  // },
  // {
  //   action:"order-accept",
  //   label:"Order Accept"
  // },
];
export const GENERAL_SETTINGS = {
  label: "Place-Order"
};

export const OFFLINE_SETTINGS = {
  label: "Offline-Stock"
};

export const EMAIL_RECEIVER_SETTINGS = {
  label: "email-receiver"
};

export const MasterAction = {
  color: "color",
  clarity: "clarity",
  Symmetry: "Symmetry",
  shape: "shape",
  fluorescence: "fluorescence",
  Location: "Location",
  SIZE: "SIZE",
  Polish: "Polish",
  cut: "cut"
};

export const diamondParameters = [
  { key: "showIdName", name: "Stone Id" },
  { key: "stoneId", name: "Stone Id" },
  { key: "shapeId", name: "Shape" },
  { key: "colorId", name: "Color" },
  { key: "clarityId", name: "Clarity" },
  { key: "fluorescenceId", name: "Fluorescence" },
  { key: "colorShadeId", name: "Color Shade" },
  { key: "labId", name: "Lab" },
  { key: "cutId", name: "Cut" },
  { key: "polishId", name: "Polish" },
  { key: "symmetryId", name: "Symmetry" },
  { key: "locationId", name: "Location" },
  { key: "fancyColorIntensity", name: "Intensity" },
  { key: "fancyColorOvertone", name: "Overtone" },
  {
    key: "blackInclusionTableId",
    name: "Black Table"
  },
  { key: "openTableId", name: "Open Table" },
  {
    key: "blackInclusionCrownId",
    name: "Black Crown"
  },
  { key: "openCrownId", name: "Open Crown" },
  {
    key: "whiteInclusionTableId",
    name: "White Table"
  },
  { key: "openPavilionId", name: "Open Pavilion" },
  {
    key: "whiteInclusionCrownId",
    name: "White Crown"
  },
  { key: "eyeCleanId", name: "Eye Clean" },
  { key: "milkeyId", name: "Milky" },
  { key: "hAndAId", name: "Heart and Arrow" },
  { key: "roughOriginId", name: "Rough Origin" },
  { key: "marketingBack", name: "Rap Discount" },
  { key: "marketingPricePerCarat", name: "Price/Carat" },
  { key: "totalDepth", name: "Total Depth" },
  { key: "tablePer", name: "Table" },
  { key: "girdlePer", name: "Girdle" },
  { key: "pAngle", name: "Pavilion Angle" },
  { key: "cAngle", name: "Crown Angle" },
  { key: "diameterMin", name: "Diameter Min" },
  { key: "diameterMax", name: "Diameter Max" },
  { key: "carat", name: "Carat" },
  { key: "ratio", name: "Ratio" },
  { key: "marketingIssueDate", name: "New Arrivals" },
  { key: "showSrNo", name: "Show ID" },
  { key: "keyToSymbol", name: "Key To Symbol" },
  { key: "fancyColor", name: "Fancy Color" }
];

export const diamondparameters2 = [
  { key: "stoneId", name: "Stone Id" },
  { key: "shapeId", name: "Shape" }
];
export const SEARCH_HISTORY = {
  RECENT: 1,
  SAVE: 2,
  DEMAND: 3,
  VOICE_SEARCH: 6,
  MATCH_PAIR: 5
};
export const OVERFLOW_COUNT = 15000;
export const PAGES_PERMISSION = {
  "1": [
    1,
    60,
    61,
    62,
    63,
    64,
    65,
    66,
    67,
    71,
    72,
    73,
    74,
    75,
    76,
    77,
    78,
    80,
    81,
    82,
    83,
    84,
    85,
    86,
    87,
    88,
    89,
    90,
    91,
    92,
    93,
    94,
    95,
    96,
    97,
    98,
    99,
    100,
    101,
    102,
    103,

    104,
    181,
    182,
    183,
    105,
    106,

    120,
    118, //Appointment
    119, //Slot
    107,
    108,
    109,
    110,
    111,
    112,
    113,
    121, //parcel-master
    122, //setting
    123, //notification-master
    124,
    125,
    126,
    127,
    128,
    129,
    130,
    131,
    140,
    141,
    151,
    160,
    161, //search-summary
    163, //hit-counter
    164, //location
    145, //sync setting
    165, //guest
    166,
    167,
    168,
    169,
    170,
    171,
    172,
    173,
    174,
    175,
    176,
    177,
    178,
    // 179,
    180,
    184
  ],
  "3": [1],
  "4": [1]
};

export const IMAGE_PATH =
  "https://diamanti.s3.amazonaws.com/images/diamond/***.jpg";
export const VIDEO_PATH =
  "https://diamanti.s3.amazonaws.com/video/Vision360.html?d=***";
export const CERTI_PATH =
  "http://diamanti.s3.amazonaws.com/certificates/***.jpg";
const BACKEND_URL = "https://admin.hk.co/";
// const BACKEND_URL = "http://localhost:7027/";
// const BACKEND_URL = "http://172.16.5.80:7027/";
export const BASE_URL = BACKEND_URL;

export const BASE_LINK = "";
export const BASE_URL_FILE_UPLOAD = "https://attachment.hk.co/";
// export const BASE_URL_FILE_UPLOAD = "http://localhost:7028/";

export const WEBSITE_BASE_URL = "https://my.hk.co/";

export const BASE_SYNC_URL = "https://sync.hk.co/";

export const DEFAULT_API_ERROR = "Something went wrong. Please contact admin.";

export const EXCELFILE_URL = BASE_URL + "data/";

export const CURRENT_PAGE = 1;
export const CURRENT_SIZE = 200;
