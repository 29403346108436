import React from 'react'
import { getItem } from "../util/storage";
import { Redirect, Route } from "react-router-dom";
import { USERS_TYPE } from "../constants/Common";

export default function ProtectedRoutes({ path, component, action = "view", module }) {
    const user = JSON.parse(getItem("user"));
    if (user.type === USERS_TYPE.SUPER_ADMIN) {
        return <Route to={path} component={component} />
    }
    else {
        const permission = JSON.parse(getItem("permission"));
        // console.log(permission)
        if (permission && permission.length) {
            let modulePermission = permission.filter(f => f.module === module)
            // console.log(modulePermission, module, action)
            if (modulePermission && modulePermission.length) {
                modulePermission = modulePermission[0][action];
                return <Route to={path} component={component} />
            }
            else return <Redirect to="/hkadmin/dashboard" />
        }
        else return <Redirect to="/hkadmin/dashboard" />
    }
}
