import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Button, Form, Input } from "antd";
import IntlMessages from "util/IntlMessages";
import axios from 'util/Api';
import OpenNotification from "../../../constants/OpenNotifications";

const FormItem = Form.Item;

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    loading: true
                });
                axios.post(
                    'auth/forgot-password',
                    { username: values.email }
                ).then(({ data }) => {
                    if (data.code === 'OK') {

                      OpenNotification({
                        type: 'success',
                        title: data.message
                      })
                      this.props.form.resetFields();
                    }
                    this.setState({
                        loading: false
                    });
                }).catch((error) => {

                    this.setState({
                        loading: false
                    });

                  OpenNotification({
                    type: 'error',
                    title: error.message
                  })
                });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-login-container">
                <div className="gx-login-content">

                    <div className="gx-login-header">
                        <img src={require("assets/images/logo-white.svg")} alt="wieldy" title="wieldy" />
                    </div>
                    <div className="gx-mb-4">
                        <h2>Forgot Your Password ?</h2>
                        <p>Don't worry. Recovering the password is easy. Just tell us the email.</p>
                    </div>
                    <Form layout="vertical" onSubmit={this.handleSubmit} className="gx-login-form gx-form-row0">
                        <FormItem>
                            {getFieldDecorator('email', {
                                rules: [{
                                    type: 'email', message: 'The input is not valid E-mail!',
                                }, {
                                    required: true, message: 'Please input your E-mail!',
                                }],
                            })(
                                <Input type="email" placeholder="E-Mail Address" />
                            )}
                        </FormItem>

                        <FormItem>
                            <Button type="primary" className="gx-mb-0" htmlType="submit">
                                <IntlMessages id="app.userAuth.send" />
                            </Button>
                            <Link to='/hkadmin/signin' >
                                <span className="gx-signup-form-forgot gx-link">
                                    Back to login.
                                </span>
                            </Link>
                        </FormItem>
                    </Form>

                </div>
            </div>
        );
    }
}

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default (WrappedForgotPasswordForm);
