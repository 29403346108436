import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  USER_DEFAULT_PASSWORD_SET,
  USERNAME_PASSWORD,
  REMOVE_USERNAME_PASSWORD,
  VERIFY_OTP
} from "../../constants/ActionTypes";
import OneSignal from 'react-onesignal';
import axios from "util/Api";
import { setItem, removeItem } from "../../util/storage";
export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({ email, password, name }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/register", {
        email: email,
        password: password,
        name: name
      })
      .then(({ data }) => {
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axios.defaults.headers.common["access-token"] =
            "JWT " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const userGetOTP = ({ username, password, isResendOtp }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("admin/auth/send-login-otp", {
        username: username,
        password: password,
        isResendOtp: isResendOtp || undefined
      })
      .then(({ data }) => {
        if (data.code === "OK") {
          dispatch({
            type: USERNAME_PASSWORD,
            payload: {
              username: username,
              password: password
            }
          });

          if (isResendOtp) {
            dispatch({ type: SHOW_MESSAGE, payload: data.message });
          } else {
            dispatch({ type: FETCH_SUCCESS });
          }
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error.response && error.response.data
              ? error.response.data.message
              : "Something went wrong"
        });
      });
  };
};


export const userSignIn = (request, cb) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("admin/auth/login", {
        ...request
      })
      .then(({ data }) => {
        if (data.code === "OK") {
          const user = data.data.user;
          if (user.type !== 1) {
            const sellerData = data.data.user.sellerDetail;
            OneSignal.setExternalUserId(sellerData.sellerId)
          }
          if (user.defaultPassword) {
            dispatch({
              type: USER_DEFAULT_PASSWORD_SET,
              payload: {
                userId: user.id,
                defaultPassword: user.defaultPassword,
                token: "JWT " + data.data.token.jwt
              }
            });
          } else {
            localStorage.setItem("token", JSON.stringify(data.data.token.jwt));
            setItem("user", JSON.stringify(user));
            setItem(
              "permission",
              JSON.stringify(data.data.userPermissions.data)
            );
            localStorage.setItem("flag", 1)
            axios.defaults.headers.common["access-token"] =
              "JWT " + data.data.token.jwt;
            dispatch({ type: USER_TOKEN_SET, payload: data.data.token.jwt });
            dispatch({ type: USER_DATA, payload: data.data.user });
          }

          dispatch({ type: VERIFY_OTP, payload: true })
          dispatch({ type: REMOVE_USERNAME_PASSWORD })
          dispatch({ type: FETCH_SUCCESS });
          cb(true)
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
          dispatch({ type: VERIFY_OTP, payload: false })
          cb(false)
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error.response && error.response.data
              ? error.response.data.message
              : "Something went wrong"
        });
      });
  };
};

export const getUser = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    // axios
    //   .post("auth/me")
    //   .then(({ data }) => {
    //     if (data.result) {
    //       dispatch({ type: FETCH_SUCCESS });
    //       dispatch({ type: USER_DATA, payload: data.user });
    //     } else {
    //       dispatch({ type: FETCH_ERROR, payload: data.error });
    //     }
    //   })
    //   .catch(function(error) {
    //     dispatch({ type: FETCH_ERROR, payload: error.message });
    //   });
  };
};

export const userSignOut = () => {
  var body = document.body;
  body.classList.add("showLoading");

  return dispatch => {
    dispatch({ type: FETCH_START });
    setTimeout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      removeItem("permission");

      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: SIGNOUT_USER_SUCCESS });
      body.classList.remove("showLoading");
    }, 2000);
  };
};
