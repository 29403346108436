import CryptoJS from 'crypto-js'

const PASSWORD = process.env.SALT || "hkw"

export const setItem = (key, value) => {
  const encryptedValue = encrypt(value, PASSWORD)
  try {
    localStorage.setItem(key,value);
    localStorage.setItem(key, encryptedValue)
    return 0
  } catch (error) {
    console.error(error);
    return 1
  }
}

export const getItem = (key) => {
  try {
    if (localStorage.getItem(key)) return decrypt(localStorage.getItem(key), PASSWORD)
    // return localStorage.getItem(key);
    else return null
  } catch (error) {
    if (!localStorage.getItem("flag")) {
      localStorage.clear();
      window.location.reload()
    }
  }
}

export const removeItem = (key) => localStorage.removeItem(key) || null

const encrypt = (data, password) => {
  var encrypted = CryptoJS.AES.encrypt(data, password);
  var transitmessage = encrypted.toString();
  return transitmessage
}

const decrypt = (encryptedData, password) => {
  var encrypted = encryptedData;
  var decrypted = CryptoJS.AES.decrypt(encrypted, password).toString(CryptoJS.enc.Utf8)
  return decrypted;
}
