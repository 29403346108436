import React, { Component } from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";

import languageData from "../../containers/Topbar/languageData";
import HorizontalNav from "../Topbar/HorizontalNav";
import {
  switchLanguage,
  toggleCollapsedSideNav
} from "../../appRedux/actions/Setting";
import UserProfile from "../Sidebar/UserProfile";

const { Header } = Layout;

class InsideHeader extends Component {
  state = {
    searchText: ""
  };

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={e => this.props.switchLanguage(language)}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  updateSearchChatUser = evt => {
    this.setState({
      searchText: evt.target.value
    });
  };

  render() {
    const { navCollapsed } = this.props;

    return (
      <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
        <Header
          className="gx-header-horizontal-main"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="gx-container">
            <div className="gx-header-horizontal-main-flex">
              <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
                <i
                  className="gx-icon-btn icon icon-menu"
                  onClick={() => {
                    this.props.toggleCollapsedSideNav(!navCollapsed);
                  }}
                  style={{ color: '#000', margin: -15 }}
                />
              </div>
              <div
                className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block"
                style={{ position: "absolute", right: "0px", top: "8px" }}
              >
                <HorizontalNav />
                <UserProfile />
              </div>
              {/* <ul className="gx-header-notifications gx-ml-auto">
                <li className="gx-user-nav"><UserInfo/></li>
              </ul> */}
            </div>
          </div>
        </Header>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale, navCollapsed } = settings;

  return { locale, navCollapsed };
};
export default connect(mapStateToProps, {
  toggleCollapsedSideNav,
  switchLanguage
})(InsideHeader);
