import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover, Modal, Row, Col, notification } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import ConstantApi from "../../constants/CommonApi";
import UtilService from "../../services/util";
import OpenNotification from "../../constants/OpenNotifications";
import CircularProgress from "components/CircularProgress";
class UserProfile extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      newpassword: "",
      currentpassword: "",
      confirmpassword: "",
      errors: {
        newpassword: "",
        confirmpassword: "",
        currentpassword: ""
      },
      loader: false
    };
  }
  cancel = () => {
    this.setState({
      showModal: false,
      confirmpassword: "",
      newpassword: "",
      currentpassword: "",
      errors: {}
    });
  };

  handleValidation() {
    let values = {
      newpassword: this.state.newpassword,
      confirmpassword: this.state.confirmpassword,
      currentpassword: this.state.currentpassword
    };
    let errors = {};
    let formIsValid = true;

    if (!values.newpassword) {
      formIsValid = false;
      errors["newpassword"] = "Please enter new password";
    }

    if (
      values.confirmpassword &&
      values.confirmpassword !== values.newpassword
    ) {
      formIsValid = false;
      errors["confirmpassword"] = "Password not matched";
    } else if (!values.confirmpassword) {
      formIsValid = false;
      errors["confirmpassword"] = "Please enter confirm password";
    }

    this.setState({
      errors
    });
    return formIsValid;
  }

  ok = () => {
    let blank = [];
    let invalid = [];
    let self = this;
    let obj = ConstantApi.ResetPassword.paginate;
    obj.request = {
      ConfirmPassword: self.state.confirmpassword,
      currentPassword: self.state.currentpassword,
      newPassword: self.state.newpassword
    };
    if (self.handleValidation()) {
      UtilService.callApi(obj, function(err, data) {
        // console.log("data", data);
        if (data.code === "OK") {
          OpenNotification({
            type: "success",
            title: data.message
          });
          self.setState({
            newpassword: "",
            confirmpassword: "",
            currentpassword: "",
            showModal: false,
            errors: {}
          });
        } else {
          self.setState({
            newpassword: "",
            confirmpassword: "",
            currentpassword: ""
            // showModal:false
          });
        }
      });
    } else {
      if (blank.length > 0) {
        let blankFields = blank.map((f, i) => {
          return <span>{f + (i !== blank.length - 1 ? ", " : "")}</span>;
        });
        notification.error({
          message: "Required fields",
          description: blankFields
        });
      }
      if (invalid.length > 0) {
        let blankFields = invalid.map((f, i) => {
          return <span>{f + (i !== invalid.length - 1 ? ", " : "")}</span>;
        });
        notification.error({
          message: "Invalid fields",
          description: blankFields
        });
      }
    }
    // }
  };

  handleOnChange = (title, e) => {
    if (title === "newpassword") {
      this.setState({
        newpassword: e.target.value
      });
    }

    if (title === "confirmpassword")
      this.setState({
        confirmpassword: e.target.value
      });

    if (title === "currentpassword")
      this.setState({
        currentpassword: e.target.value
      });
  };

  render() {
    const { authUser } = this.props;
    const { newpassword, confirmpassword, currentpassword } = this.state;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.setState({ showModal: true })}>
          Reset Password
        </li>
        <li
          onClick={() => {
            this.setState({ loader: true });

            this.props.userSignOut();
          }}
        >
          {" "}
          Logout
        </li>
      </ul>
    );

    return (
      <div>
        <Popover
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
        >
          <Avatar
            src="https://via.placeholder.com/150x150"
            className="gx-size-40 gx-pointer gx-mr-3"
            alt=""
          />
          <span className="gx-avatar-name">
            {authUser ? authUser.username : "Loading"}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />{" "}
            {this.state.loader && <CircularProgress />}
          </span>
          &nbsp;
        </Popover>
        {this.state.showModal && (
          <Modal
            visible={this.state.showModal}
            title={"Reset Password"}
            okText={"Reset Password"}
            onOk={this.ok}
            onCancel={this.cancel}
          >
            <form autocomplete="off">
              <Row>
                <Col lg={24} className="reset-password-box mb-20">
                  <div className="form-control">
                    <label className="d-block">
                      Current Password{" "}
                      {currentpassword === "" ? (
                        <span className="required" style={{ color: "red" }}>
                          *
                        </span>
                      ) : (
                        <span className="required" style={{ color: "green" }}>
                          *
                        </span>
                      )}
                    </label>

                    <input
                      type="password"
                      id="currentpassword"
                      value={currentpassword ? currentpassword : ""}
                      onChange={e => {
                        this.handleOnChange("currentpassword", e);
                      }}
                    />

                    <span style={{ color: "red" }}>
                      {this.state.errors["currentpassword"]}
                    </span>
                  </div>
                </Col>
                <Col lg={24} className="reset-password-box mb-20">
                  <div className="form-control">
                    <label className="d-block">
                      New Password{" "}
                      {newpassword === "" ? (
                        <span className="required" style={{ color: "red" }}>
                          *
                        </span>
                      ) : (
                        <span className="required" style={{ color: "green" }}>
                          *
                        </span>
                      )}
                    </label>

                    <input
                      type="password"
                      id="newpassword"
                      value={newpassword ? newpassword : ""}
                      onChange={e => {
                        this.handleOnChange("newpassword", e);
                      }}
                    />
                    <span style={{ color: "red" }}>
                      {this.state.errors["newpassword"]}
                    </span>
                  </div>
                </Col>
                <Col lg={24} className="reset-password-box mb-20">
                  <div className="form-control">
                    <label className="d-block">
                      Confirm Password{" "}
                      {confirmpassword === "" ? (
                        <span className="required" style={{ color: "red" }}>
                          *
                        </span>
                      ) : (
                        <span className="required" style={{ color: "green" }}>
                          *
                        </span>
                      )}
                    </label>

                    <input
                      type="password"
                      id="confirmpassword"
                      value={confirmpassword ? confirmpassword : ""}
                      onChange={e => {
                        this.handleOnChange("confirmpassword", e);
                      }}
                    />

                    <span style={{ color: "red" }}>
                      {this.state.errors["confirmpassword"]}
                    </span>
                  </div>
                </Col>
              </Row>
            </form>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, { userSignOut })(UserProfile);
