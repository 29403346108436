import React from "react";
import { Button, Form, Input } from "antd";
import { connect } from "react-redux";
import { userGetOTP, userSignIn } from "../appRedux/actions/Auth";
import InfoView from "components/InfoView";
import _ from "lodash";

const FormItem = Form.Item;

class OTPVerification extends React.Component {

  componentDidMount() {
    if (_.isEmpty(this.props.userData)) {
      this.props.history.push("/hkadmin/signin");
    }
  }


  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      let obj = {
        ...this.props.userData,
        ...values
      }
      if (!err) {
        this.props.userSignIn(obj, cb => {
          if ((cb && this.props.token !== null) ||
            (this.props.defaultPassword && this.props.defaultPassword.userId)) {
            this.props.history.push("/")
          }
        });
      }
    });
  };

  resendOTP = () => {
    let values = this.props.userData;
    values.isResendOtp = true;
    this.props.userGetOTP(values)
  }


  componentDidUpdate() {
    // if (
    //     this.props.token !== null ||
    //     (this.props.defaultPassword && this.props.defaultPassword.userId) &&
    //     this.props.isVerified
    // ) {
    //     this.props.history.push("/");
    // }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img
                  src={require("assets/images/bg-vision.png")}
                  alt="Neature"
                />
              </div>
              <div className="gx-app-logo-wid">
                <h1>OTP Verification</h1>
                <p>
                  Enter your OTP.
                </p>
              </div>
              <div className="gx-app-logo signinlogo">
                <img
                  alt="example"
                  src={require("assets/images/logo-white.svg")}
                />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                onSubmit={this.handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem>
                  {getFieldDecorator("otp", {
                    rules: [
                      { required: true, message: "Please input your otp!" }
                    ]
                  })(<Input type="text" placeholder="OTP" />)}
                </FormItem>
                <FormItem>
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    Verify
                                    </Button>

                  <Button type="primary" className="gx-mb-0" onClick={() => this.resendOTP('')}>
                    Re-send
                                    </Button>

                  <Button type="primary" className="gx-mb-0" onClick={() => window.location.href = '/'}>
                    Cancel
                                    </Button>
                </FormItem>
              </Form>
            </div>
            <InfoView />
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalOTPForm = Form.create()(OTPVerification);

const mapStateToProps = ({ auth }) => {
  const { defaultPassword, token, userData, isVerified } = auth;
  return { defaultPassword, token, userData, isVerified };
};

export default connect(mapStateToProps, { userGetOTP, userSignIn })(WrappedNormalOTPForm);
