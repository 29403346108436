import { INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET, USER_DEFAULT_PASSWORD_SET, USERNAME_PASSWORD, REMOVE_USERNAME_PASSWORD, VERIFY_OTP } from "../../constants/ActionTypes";
import { getItem } from "../../util/storage";

const INIT_STATE = {
  token: localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : JSON.parse(localStorage.getItem('token')),
  initURL: '',
  authUser: JSON.parse(getItem('user')),
  defaultPassword: null,
  userData: {},
  isVerified: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: ''
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case USER_DEFAULT_PASSWORD_SET: {
      return {
        ...state,
        defaultPassword: action.payload,
      };
    }

    case USERNAME_PASSWORD: {
      return {
        ...state,
        userData: action.payload,
      };
    }

    case VERIFY_OTP: {
      return {
        ...state,
        isVerified: action.payload,
      };
    }

    case REMOVE_USERNAME_PASSWORD: {
      return {
        ...state,
        userData: {}
      };
    }


    default:
      return state;
  }
}
//comment
