const ConstantApi = {
  LoginSummary: {
    paginate: { method: 'post', url: 'user/login/log/paginate' },
  },
  ReportLog: {
    upsert: { method: 'post', url: '/admin/report/log/upsert' },
  },
  NewDashboard: {
    paginate: { method: 'post', url: '/apis/dashboard-data/paginate' },
    commentCount: { method: 'post', url: '/apis/CommentsCount/paginate' },
    getTrackTypeSummary: { method: 'post', url: '/apis/CommentsCount/summary' },
    demandSummary: { method: 'post', url: '/apis/Demand/summary' },
    getDiamondTrackData: { method: 'post', url: '/admin/diamond-track/paginate' },
    demandDetails: { method: 'post', url: '/admin/dashbord/demanddetails' },
  },
  Master: {
    paginate: { method: 'post', url: 'apis/master/paginate' },
    listByCode: { method: 'post', url: 'apis/master/list-by-code' },
    update: { method: 'put', url: 'apis/master/' },
    syncPaginate: {
      method: 'post',
      url: '/admin/hk/sync/master',
      isSync: true,
    },
    sequenceUpdate: { method: 'post', url: 'apis/master/bulk-sequence-update' },
  },
  Account: {
    paginate: { method: 'post', url: 'apis/account/paginate' },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/ledger', isSync: true },
    update: { method: 'get', url: 'apis/account/' },
  },
  Event: {
    paginate: { method: 'post', url: 'apis/event/paginate' },
    create: { method: 'post', url: 'apis/event/create' },
    update: { method: 'put', url: 'apis/event/' },
    list: { method: 'get', url: 'apis/event/' },
    sequenceUpdate: { method: 'post', url: '/apis/event/sequence' },
  },
  NonCert: {
    paginate: { method: 'post', url: 'admin/cert/paginate' },
    create: { method: 'post', url: 'admin/cert/create' },
    update: { method: 'put', url: 'admin/cert/' },
    list: { method: 'get', url: 'admin/cert/view/' },
    exportCriteria: { method: 'get', url: 'admin/criteria/export/' },
    toogleAction: { method: 'post', url: 'admin/noncert/toggle' },
  },
  NonCertCriteria: {
    create: { method: 'post', url: 'admin/criteria/create' },
    list: { method: 'get', url: 'admin/criteria/view/' },
  },
  NonCertDetails: {
    list: { method: 'post', url: 'admin/details/view' },
    update: { method: 'put', url: 'admin/details/diamond/' },
    getDiamond: { method: 'get', url: 'admin/details/diamond/' },
  },
  LayoutMaster: {
    paginate: { method: 'post', url: 'admin/master/master' },
    submaster: { method: 'post', url: 'admin/submaster/paginate' },
    addLayoutMaster: { method: 'post', url: 'admin/layoutmaster/upsert' },
    addLayoutSubMaster: { method: 'post', url: 'admin/layoutmaster/create' },
  },
  Certified: {
    paginate: { method: 'post', url: 'admin/layout/paginate' },
    create: { method: 'post', url: 'admin/layout/create' },
    update: { method: 'put', url: 'admin/layout/' },
    list: { method: 'get', url: 'admin/layout/view/' },
    getPrice: { method: 'post', url: 'admin/layout/price' },
  },
  Contactus: {
    paginate: { method: 'post', url: 'apis/contactus/paginate' },
    delete: { method: 'post', url: '/apis/contactus/delete' },
  },
  Sizemaster: {
    paginate: { method: 'post', url: 'apis/size/paginate' },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/size', isSync: true },
  },
  Terms: {
    paginate: { method: 'post', url: 'apis/day-term/paginate' },
    list: { method: 'get', url: '/apis/day-term/' },
    syncPaginate: {
      method: 'post',
      url: '/admin/hk/sync/accountTerm',
      isSync: true,
    },
  },
  Gridcolumn: {
    list: { method: 'post', url: 'apis/grid/get-columns' },
    update: { method: 'post', url: 'apis/grid/upsert' },
    dropdownlist: { method: 'post', url: 'apis/grid/list' },
  },
  Comment: {
    paginate: { method: 'post', url: 'apis/diamond-comment/by-user' },
  },
  Diamond: {
    paginate: { method: 'post', url: 'apis/diamond/paginate' },
    syncPaginate: {
      method: 'post',
      url: '/admin/hk/sync/diamond',
      isSync: true,
    },
  },
  Country: {
    paginate: { method: 'post', url: 'apis/country/paginate' },
    syncPaginate: {
      method: 'post',
      url: 'admin/hk/sync/country',
      isSync: true,
    },
    update: { method: 'put', url: 'apis/country/' },
  },
  State: {
    paginate: { method: 'post', url: 'apis/state/paginate' },
    syncPaginate: {
      method: 'post',
      url: 'admin/hk/sync/state',
      isSync: true,
    },
    update: { method: 'put', url: 'apis/state/' },
  },
  City: {
    paginate: { method: 'post', url: 'apis/city/paginate' },
    syncPaginate: {
      method: 'post',
      url: 'admin/hk/sync/city',
      isSync: true,
    },
    update: { method: 'put', url: 'apis/city/' },
  },

  Parcel_master: {
    paginate: { method: 'post', url: 'apis/parcel-price/paginate' },
    dropdownlist: { method: 'post', url: 'apis/parcel-price/get-dates' },
    update: { method: 'post', url: 'apis/parcel-price/upsert' },
  },

  ParcelOrder: {
    paginate: { method: 'post', url: 'apis/parcel-order/paginate' },
    updateStatus: { method: 'put', url: 'apis/parcel-order/update-status/' },
  },

  Feedback: {
    paginate: { method: 'post', url: 'apis/feedback/paginate' },
    destroy: { method: 'post', url: 'apis/feedback/destroy' },
  },
  FeaturedStone: {
    paginate: { method: 'post', url: 'apis/featuredStone/paginate' },
    destroy: { method: 'post', url: 'apis/featuredStone/destroy' },
    create: { method: 'post', url: 'apis/featuredStone/create' },
  },
  TrackType: {
    paginate: { method: 'post', url: 'apis/diamond-track/paginate' },
  },
  ActiveDeactive: {
    list: { method: 'post', url: 'admin/common/boolean-status-update' },
  },
  User: {
    paginate: { method: 'post', url: 'apis/user/paginate' },
    syncPaginate: {
      method: 'post',
      url: 'admin/hk/sync/user',
      isSync: true,
    },
    ResetPassword: { method: 'post', url: 'apis/user/reset-password' },
    Upsert: { method: 'post', url: 'apis/user/create' },
    edit: { method: 'put', url: '/apis/user/' },
  },
  Permission: {
    update: { method: 'post', url: 'apis/permission/upsert' },
    paginate: { method: 'post', url: 'apis/permission/paginate' },
    listByRole: { method: 'get', url: 'apis/permission/view-by-role/' },
    listByCode: { method: 'post', url: 'apis/master/list-by-code' },
    listByUser: { method: 'post', url: 'apis/permission/user-permission' },
  },
  Setting: {
    getSetting: { method: 'get', url: 'apis/setting/' },
    upsert: { method: 'post', url: '/apis/setting/upsert' },
    paginate: { method: 'post', url: '/apis/setting/paginate' },
  },
  Notification: {
    paginate: { method: 'post', url: 'apis/notification/paginate' },
    upsert: { method: 'post', url: '/apis/notification/sendNotification' },
  },
  PageTracking: {
    paginate: { method: 'post', url: '/apis/analytics/paginate' },
  },
  Transport: {
    paginate: { method: 'post', url: '/apis/transport/paginate' },
    update: { method: 'post', url: '/apis/transport/update' },
  },
  Hospitality: {
    paginate: { method: 'post', url: '/apis/product-order/paginate' },
    update: { method: 'post', url: '/apis/product-order/update' },
  },
  Career: {
    paginate: { method: 'post', url: '/front/career/paginate' },
  },
  Product: {
    paginate: { method: 'post', url: '/apis/master/list-by-code' },
    mainPaginate: { method: 'post', url: '/apis/product/paginate' },
    sequenceUpdate: {
      method: 'post',
      url: '/apis/product/bulk-sequence-update',
    },
  },
  Platform: {
    paginate: { method: 'post', url: '/apis/user/by-login-device-type' },
  },
  ResetPassword: {
    paginate: { method: 'post', url: '/auth/reset-password-by-user' },
  },
  SearchHistory: {
    paginate: { method: 'post', url: '/apis/diamond/search/history' },
    history: { method: 'post', url: '/apis/diamond/search/report' },
  },
  LayoutSearch: {
    paginate: { method: 'post', url: '/admin/layoutsearch/paginate' },
  },
  SearchSummary: {
    paginate: { method: 'post', url: '/admin/search/summary' },
  },
  // BestViewDiamond: {
  //   paginate: { method: "post", url: "/admin/bestviewstone/paginate" },
  // },
  FourC: {
    paginate: { method: 'post', url: '/apis/diamond/fourcsearch/paginate' },
  },
  Location: {
    paginate: { method: 'post', url: '/apis/news-event/paginate' },
    getsingle: { method: 'get', url: '/apis/news-event/' },
    upsert: { method: 'put', url: '/apis/news-event/' },
  },
  Syncsetting: {
    paginate: { method: 'post', url: '/apis/last-sync/paginate' },
    upsert: { method: 'put', url: '/apis/last-sync/' },
  },
  DiamondJourney: {
    paginate: { method: 'post', url: '/admin/diamond-origin' },
  },
}
export default ConstantApi
