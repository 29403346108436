import axios from "axios";
// import { BASE_URL } from "../constants/ThemeSetting";
import { cacheAdapterEnhancer } from 'axios-extensions';

import { BASE_URL } from "../constants/Common";

export default axios.create({
  baseURL: BASE_URL,
     adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache'}),
  headers: {
    "Content-Type": "application/json",
     'Cache-Control': 'no-cache'
  }
});
