import React from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { userGetOTP, userSignIn } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import _ from "lodash";
import axios from '../util/Api';
import { setItem } from "../util/storage";
const FormItem = Form.Item;

class SignIn extends React.Component {
  componentWillMount() {

    let token = window.location.search && window.location.search.length && window.location.search.includes('?token=') ? window.location.search.split('?token=')[1] : null
    if (token) {
     // let self = this
      localStorage.setItem('token', JSON.stringify(token))
      let headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + token
        }
      }
      axios.get("/admin/user-setting/get-login-user", headers).then(({ data }) => {
        if (data && data.code === "OK") {
          const user = data.data.user;
          // if (user.defaultPassword) {
          //   dispatch({
          //     type: USER_DEFAULT_PASSWORD_SET,
          //     payload: {
          //       userId: user.id,
          //       defaultPassword: user.defaultPassword,
          //       token: "JWT " + data.data.token.jwt
          //     }
          //   });
          // } else {
          // localStorage.setItem("token", JSON.stringify(data.data.token.jwt));
          setItem("user", JSON.stringify(user));
          setItem(
            "permission",
            JSON.stringify(data.data.userPermissions.data)
          );
          localStorage.setItem("flag", 1)
          // self.props.history.push('/dashboard')
          window.location.assign('/hkadmin/dashboard')
          // axios.defaults.headers.common["access-token"] =
          //   "JWT " + data.data.token.jwt;
          // dispatch({ type: USER_TOKEN_SET, payload: data.data.token.jwt });
          // dispatch({ type: USER_DATA, payload: data.data.user });
          // }
        }
      }).catch(err => {
        // console.log('err', err)
      })
      // this.props.history.push('/dashboard')
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userGetOTP(values);
      }
    });
  };

  componentDidUpdate() {
    if (
      this.props.userData &&
      !_.isEmpty(this.props.userData) &&
      this.props.userData.username &&
      this.props.userData.password
    ) {
      this.props.history.push("/hkadmin/otp-verification");
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img
                  src={require("assets/images/bg-vision.png")}
                  alt="Neature"
                />
              </div>
              <div className="gx-app-logo-wid">
                <h1>Sign In</h1>
                <p>
                  By Signing Up, you can avail full features of our services.
                </p>
              </div>
              <div className="gx-app-logo signinlogo">
                <img
                  alt="example"
                  src={require("assets/images/logo-white.svg")}
                />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                onSubmit={this.handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem>
                  {getFieldDecorator("username", {
                    rules: [
                      {
                        required: true,
                        message: "The input is not valid Username!"
                      }
                    ]
                  })(<Input placeholder="Username" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("password", {
                    rules: [
                      { required: true, message: "Please input your Password!" }
                    ]
                  })(<Input type="password" placeholder="Password" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("remember", {
                    valuePropName: "checked",
                    initialValue: true
                  })(
                    <Checkbox>
                      <IntlMessages id="appModule.iAccept" />
                    </Checkbox>
                  )}
                  <span className="gx-signup-form-forgot gx-link"></span>
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                  <Link to="/hkadmin/forgot-password">
                    <span className="gx-signup-form-forgot gx-link">
                      <IntlMessages id="app.userAuth.forgotPassword" /> ?
                    </span>
                  </Link>
                </FormItem>
              </Form>
            </div>
            <InfoView />
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { defaultPassword, token, userData } = auth;
  return { defaultPassword, token, userData };
};

export default connect(mapStateToProps, { userGetOTP, userSignIn })(
  WrappedNormalLoginForm
);
