import React from "react";
import ReactDOM from "react-dom";
// import { notification } from 'antd';
import NextApp from './NextApp';
import registerServiceWorker from './registerServiceWorker';
// Add this import:
import { AppContainer } from 'react-hot-loader';
// import OneSignal from 'react-onesignal';

// OneSignal.init({
//   appId: "6f3e694b-b00e-48fe-aeb8-c017adbe6ba0",
//   safari_web_id: "web.onesignal.auto.5e2915a8-1095-4900-b2af-7b25bf2970dd",
//   allowLocalhostAsSecureOrigin: true,
//   autoRegister: true,
//   subdomainName: "https://admin.hk.co/",
// });

// OneSignal.on('notificationDisplay', function (event) {
//   if (event) {
//     notification.info({
//       message: `${event.heading}`,
//       description:`${event.content}`,
//       placement: 'topRight',
//     });
//   }
// });
// OneSignal.showNativePrompt()
// OneSignal.showSlidedownPrompt()

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <NextApp />
    </AppContainer>,
    document.getElementById('root')
  );
};

// Do this once
registerServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
